<template>
  <div id="app">
    <main>
      <p id="arrow" :style="`transform: rotate(${calculatedUp}deg);`">
       &uarr;
      </p>
    </main>
    <footer>
      <p>deviceOrientationEvent readout: {{deviceOrientation}}°</p>
      <p>deviceOrientation calc ((readout - 270) % 360): {{calculatedUp}}°</p>
      
    </footer>
  </div>

</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      deviceOrientation: 0,
      calculatedUp: 0
    }
  },
  methods: {
    gyroReader: function(event) {
      let alpha =  Math.floor(event.alpha);
      this.deviceOrientation = alpha;
      setTimeout(this.calcRotation(alpha), 5000);
    
    },
    calcRotation: function(alpha) {
      this.calculatedUp = (alpha - 270) % 360;
    }
  },
  mounted: function() {
    if (window.DeviceOrientationEvent) {
      window.addEventListener("deviceorientation", this.gyroReader, true);
    }
  }

}
</script>

<style>

html, body {
  margin: 0;
  padding: 0;
}

#app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#arrow {
  font-size: 10rem;
  transition: transform .2s ease-in-out;
}

</style>
